import React from "react";
import { Helmet } from "react-helmet";
import { graphql, useStaticQuery } from "gatsby";
import { NewsPageQuery, StrapiBlogPost } from "../../graphql-types";
import BlogPostCard from "../components/BlogPostCard";
import Footer from "../components/Footer";
import MenuBar from "../components/MenuBar";
import PageContent from "../components/PageContent";
import "./../styles/index.scss";
import ReactMarkdown from "react-markdown";
import { mustBeDefined } from "../utils";
interface StrapiBlogPostWithFeatured extends StrapiBlogPost {
  featured?: boolean;
}
const NewsPage: React.FC = () => {
  const queryResults = useStaticQuery<NewsPageQuery>(query);

  const sections = mustBeDefined(queryResults.sections).otherwise(
    "CMS_DATA_MISSING",
    "NewsPageQuery.sections"
  );

  const blogPosts = mustBeDefined(queryResults.blogPosts).otherwise(
    "CMS_DATA_MISSING",
    "NewsPageQuery.blogPosts"
  );

  const {edges} = blogPosts;
  const allBlogPosts = edges
    .map((edge) => ({
      ...edge.node,
      date: new Date(edge.node.date),
    } as StrapiBlogPostWithFeatured));


  return (
    <>
      <Helmet>
        <meta charSet="utf-8" />
        <meta name="description" content="BAM Elevate News" />
        <title>BAM Elevate - News</title>
      </Helmet>
      <main className="w-screen bg-off-white">
        <MenuBar />
        <PageContent className="pt-16">
          <div className="m-auto">
            <ReactMarkdown className="text-center text-blue text-title-mobile md:text-5xl font-title-mobile font-medium leading-none mb-5" children={sections.headerText?.data?.childMarkdownRemark?.rawMarkdownBody} />
            <ReactMarkdown className="font-sans text-center text-xs md:text-sm m-auto" children={sections.headerSubtext?.data?.childMarkdownRemark?.rawMarkdownBody} />
          </div>


          <div className="mt-16">
            {allBlogPosts.map((post, i) => (
              <BlogPostCard key={post.slug || i} {...post} />
            ))}
          </div>
          <div>
            <Footer />
          </div>
        </PageContent>
      </main>
    </>
  );
};

export const query = graphql`
  query NewsPage {
    sections: strapiNewsPage {
      headerText {
        data {
          childMarkdownRemark {
            rawMarkdownBody
          }
        }
      }
      headerSubtext {
        data {
          childMarkdownRemark {
            rawMarkdownBody
          }
        }
      }
    }
    blogPosts: allStrapiBlogPost(sort: {order: DESC, fields: date}) {
      edges {
        node {
          id
          title
          author
          body {
            data {
              childMarkdownRemark {
                rawMarkdownBody
                timeToRead
              }
            }
          }
          date
          slug
          preview
          thumbnail {
            localFile {
              publicURL
            }
          }
          stockImage {
            localFile {
              publicURL
              childImageSharp {
                gatsbyImageData(quality: 100)
              }
            }
          }
          stockImageSquare {
            localFile {
              publicURL
              childImageSharp {
                gatsbyImageData(quality: 100)
              }
            }
          }
          featured:isFeatured
        }
      }
    }
  }
`;

export default NewsPage;
