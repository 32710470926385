import React from "react";
import ElevateButton from "../ElevateButton";
import { navigate } from "gatsby";
import { StrapiBlogPost } from "../../../graphql-types";
import { GatsbyImage, getImage } from "gatsby-plugin-image";

interface BlogPostCardProps
  extends Pick<StrapiBlogPost, "title" | "body" | "id" | "slug" | "date" | "preview" | "author"> {
  thumbnail?: any;
  stockImage: any;
  stockImageSquare: any;
  featured?: boolean;
}

const BlogPostCard: React.FC<BlogPostCardProps> = ({
  title,
  date,
  body,
  author,
  slug,
  preview,
  thumbnail,
  stockImage,
  stockImageSquare,
  featured = false,
}) => {
  const formattedDate = new Date(date).toLocaleDateString("en-US", {
    day: "numeric",
    month: "short",
    year: "numeric",
  })

  return (
    <>
      <div className="hidden md:flex place-content-center w-[680px] lg:w-[820px] xl:w-[960px] 2xl:w-[1100px] m-auto mb-14">
        <div className="rounded-l-3xl bg-white flex flex-col w-1/2 p-7 lg:p-9 xl:p-11 2xl:p-12">
          <div className="w-[50%] mb-4 grid content-center">
            <img alt={title} src={thumbnail!.localFile!.publicURL} className="grayscale opacity-40 object-cover" />
          </div>
          <div className="text-xs text-gray-dark pb-1">{formattedDate}</div>
          <div className="">
            <div className="mb-2 text-lg leading-tight limit-lines w-[80%] font-semibold">{title}</div>
            {author ? <div className="mb-4 text-sm font-semibold">By {author}</div> : <div className="mb-4"></div>}
            <p className="text-gray limit-lines w-[80%]">{preview}</p>
          </div>
          <div className="grow h-0"></div>
          <div>
            <ElevateButton className="" onClick={() => navigate("/news/" + slug)}>{body.data.childMarkdownRemark.timeToRead} Minute Read</ElevateButton>
          </div>
        </div>
        <div className="h-full w-[50%]">
          <GatsbyImage loading="eager" image={getImage(stockImage.localFile.childImageSharp)} objectFit="cover" alt="" imgStyle={{borderRadius:"0 1.5rem 1.5rem 0"}}></GatsbyImage>
        </div>
      </div>

      {/* Mobile */}
      <div className="md:hidden flex flex-col m-auto mb-14 px-3 max-w-[440px]">
        <div className="h-[240px]">
          <GatsbyImage loading="eager" image={getImage(stockImageSquare.localFile.childImageSharp)} objectPosition="50% 15%" alt=""  imgStyle={{borderRadius: "1.5rem 1.5rem 0 0"}}></GatsbyImage>
        </div>
        <div className="rounded-b-3xl bg-white flex flex-col pb-4 px-4 xs:px-5 xs:pb-5 min-h-[400px]">
          <div className="w-[40%] grid content-center py-6">
            <img alt={title} src={thumbnail!.localFile!.publicURL} className="grayscale opacity-40" />
          </div>
          <div className="text-xs text-gray-dark pb-1">{formattedDate}</div>
          <div className="flex flex-col">
            <div className="text-base font-sans mb-2 leading-tight limit-lines">{title}</div>
            {author? <div className="text-sm font-sans mb-4 leading-tight">By { author }</div> : <div className="mb-4"></div>}
            <p className="text-sm text-gray-dark limit-lines">{preview}</p>
          </div>
          <div className="grow h-0 mt-3"></div>
          <ElevateButton className="p-2" onClick={() => navigate("/news/" + slug)}>{body.data.childMarkdownRemark.timeToRead} Minute Read</ElevateButton>
        </div>
      </div>
    </>
  )
};
export default BlogPostCard;
